import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CogoToast from 'cogo-toast';

import { Consumer } from '../../context';
import Request from '../../core/http';

// Components
import Header from '../../components/Header';
import Spinner from '../../components/Spinner/Spinner';

const Chat = () => {
	const [loading, setLoading] = useState(false);
	const [chat, setChat] = useState([]);
	const [respuesta, setRespuesta] = useState('');

	const history = useHistory();
	const params = useParams();

	useEffect(()=> {
		if (!params.id) {
			history.goBack();
		}
	}, []);

	useEffect(() => {
		loadChat(params.id);
	}, [params.id])

	async function loadChat(id) {
		setLoading(true);
		const response = await Request.get(`/chats/experto/${id}`);

		if (!response.error) {
			console.log(response);
			setChat(response.chat);
		} else {
			CogoToast.error(response.message || 'Conversación no encontrada');
		}

		setLoading(false);
	}

	async function enviarMensaje() {
		setLoading(true);

		if (respuesta) {
			const response = await Request.post(`/chats/${params.id}/mensaje_experto`, { respuesta });

			if (!response.error) {
				setChat(response.chat);
			} else {
				CogoToast.error(response.message || 'Conversación no encontrada');
			}
		} else {
			CogoToast.warn('Escriba un mensaje.');
		}

		setLoading(false);
	}

	return (
		<>
			<Header goBack={true}/>
			<div className="main-content">
				<div className="page chat">
					{loading?
						<div className="loading">
							<Spinner/>
						</div>
						:
						<div className="conversacion">
							{chat.map((c, index) =>
								<div key={index} className={`mensaje ${c.id_experto && 'experto'} ${c.id_cliente && 'cliente'}`}>
									<span>{c.mensaje}</span>
								</div>
							)}
							<div className="respuesta">
								<input
									value={respuesta}
									onChange={(input) => setRespuesta(input.target.value)}/>
								<button onClick={() => enviarMensaje()}>
									<i className="fa fa-send"></i>
								</button>
							</div>
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default Consumer(Chat);
