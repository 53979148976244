import React, { useState , useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CogoToast from 'cogo-toast';
import moment from 'moment-timezone';

import { Consumer } from '../../context';
import Request from '../../core/http';

//Components
import Header from '../../components/Header';
import Spinner from '../../components/Spinner/Spinner';

const Home = ({context}) => {
	const [chats, setChats] = useState([]);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		obtenerChats();
	}, []);

	async function obtenerChats() {
		setLoading(true);

		const response = await Request.get('/chats/experto');
		if (response.error) {
			CogoToast.error(response.message || 'Error al obtener conversaciones', { position: 'top-center'});
		}

		if (Array.isArray(response)) {
			setChats(response);
		}

		setLoading(false);
	}

	async function openChat(id) {
		history.push(`/chat/${id}`);
	}

	return (
		<>
			<Header/>
			<div className="main-content">
				<div className="page">
					{loading ? 
						<div className="loading row justify-center align-center">
							<Spinner/>
						</div> 
						:
						chats.map(c => 
							<div key={c.id_chat} className="card-chat" onClick={() => openChat(c.id_chat)}>
								<div className="left">
									<div className="logo">
										<img src="https://gimav.herokuapp.com/especimenes/foto_perfil/1"/>
									</div>
									<div className="info">
										<div className="especimen">
											{c.especimen}
										</div>
										<div className="cliente">
											{c.cliente}
										</div>
									</div>
								</div>
								<div className="right">
									<div className="fecha">
										{moment(c.fecha).format('DD/MM/YYYY hh:mm a')}
									</div>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</>
	)
}

export default Consumer(Home);