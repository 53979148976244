import Routes from './core/routes';
import './styles/style.scss';

function App() {
  return (
    <Routes />
  );
}

export default App;
