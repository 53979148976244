import React, { createContext, useEffect, useState } from 'react';

import Request from '../core/http';

const Context = createContext('gimav');

function Provider(props) {
	const [ value, setValue ] = useState({
		auth: false,
		loginSuccess: loginSuccess.bind(this),
		userInit: userInit.bind(this),
		logout: logout.bind(this),
		openChat: openChat.bind(this),
		user: null,
		modulos: [],
		chat: null
	});

	function loginSuccess(data) {
		if(data.auth && data.token) {
			localStorage.setItem('user', data.token);
			localStorage.setItem('auth', true);

			setValue({ ...value, user: data.token });
		}
	}

	function userInit(data) {
		console.log(data);
	}

	function logout(history) {
		if (history) {
			localStorage.removeItem('user');
			history.push('/login');
		}
	}

	async function getModulos() {
		const modulos = await Request.get('/modulos');
		if (Array.isArray(modulos)) {
			setValue({...value, modulos});
		}
	}

	async function openChat(id) {
		localStorage.setItem('chat', id);
		setValue({ ...value, chat: id });
	}

	useEffect(() => {

		const userLS = localStorage.getItem('user');
		const authLS = localStorage.getItem('auth');
		const chatLS = localStorage.getItem('chat');

		setValue({
			loginSuccess: loginSuccess.bind(this),
			userInit: userInit.bind(this),
			logout: logout.bind(this),
			openChat: openChat.bind(this),
			user: userLS,
			auth: authLS === 'true'? true : false,
			modulos: [],
			chat: chatLS,
		});

	}, []);

	useEffect(() => {
		if (value.user) {
			getModulos();
		}
	}, [value.user])

	return (
		<Context.Provider value = {value}>
			{props.children}
		</Context.Provider>
	)
}

function Consumer(Component) {
    return (props) => {
        return (
            <Context.Consumer>
                {state => <Component {...props} context={state} />}
            </Context.Consumer>
        );
    };
}

export { Provider, Consumer };
