import './Spinner.scss';

const Spinner = () => {
	return (
		<div className="spinner-loading">
			<div className="spinner-loading-container">
				<svg
					version="1.1"
					x="0px"
					y="0px">
					<circle fill="#fff" stroke="none" cx="6" cy="11" r="6">
						<animateTransform 
						attributeName="transform" 
						dur="1s" 
						type="translate" 
						values="0 5 ; 0 -5; 0 5" 
						repeatCount="indefinite" 
						begin="0.1"/>
					</circle>
					<circle fill="#fff" stroke="none" cx="30" cy="11" r="6">
						<animateTransform 
						attributeName="transform" 
						dur="1s" 
						type="translate" 
						values="0 5 ; 0 -5; 0 5" 
						repeatCount="indefinite" 
						begin="0.2"/>
					</circle>
					<circle fill="#fff" stroke="none" cx="54" cy="11" r="6">
						<animateTransform 
						attributeName="transform" 
						dur="1s" 
						type="translate" 
						values="0 5 ; 0 -5; 0 5" 
						repeatCount="indefinite" 
						begin="0.3"/>
					</circle>
				</svg>
			</div>
		</div>
	)
}

export default Spinner;