import React from 'react';
import { useHistory } from 'react-router-dom';

import { Consumer } from '../context';

const Header = (props) => {
	const history = useHistory();
	const { goBack, context } = props;

	return (
		<>
			<header>
				<div className="row align-center justify-start">
					{goBack &&
						<div className="go-back" onClick={() => history.goBack()}>
							<i className="fa fa-arrow-left"></i>
						</div>
					}
					<h1>Gimav</h1>
				</div>
				<div>
					<a onClick={() => {
						if (context) {
							console.log(context);
							context.logout(history);
						}
					}}>Salir</a>
				</div>
			</header>
		</>
	)
}

export default Consumer(Header);
