import React from 'react';
import { BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
//Api context
import { Provider } from '../context';

//Componentes
import Login from '../pages/login';
import Home from '../pages/home';
import Chat from '../pages/chat';

//Paginas

const Routes = (props) => {
	return (
		<Provider>
			<Router>
				<Switch>
					<Route path="/" exact component={Login} />
					<Route path="/login" exact component={Login} />

					<Route path="/home" exact component={Home} />
					<Route path="/chat" exact component={Chat} />
					<Route path="/chat/:id" exact component={Chat} />
				</Switch>
			</Router>
		</Provider>
	)
};

export default Routes;